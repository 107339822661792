import {ArrowsIn, ArrowsOut, MonitorPlay, Pause, Play, SpeakerHigh, SpeakerX} from '@phosphor-icons/react';
import {
	MediaControlBar,
	MediaController,
	MediaFullscreenButton,
	MediaMuteButton,
	MediaPipButton,
	MediaPlayButton,
	MediaPlaybackRateButton,
	MediaPosterImage,
	MediaTimeDisplay,
	MediaTimeRange,
	MediaVolumeRange,
} from 'media-chrome/react';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const VIDEO_CONFIG = {
	MAX_WIDTH: 400,
	PLAYBACK_RATES: [0.5, 1, 1.5, 2],
	AUTOHIDE_DELAY: 2,
} as const;

const videoCalculator = createCalculator({
	maxWidth: VIDEO_CONFIG.MAX_WIDTH,
	responsive: true,
});

type EmbedVideoProps = {
	src: string;
	width: number;
	height: number;
	placeholder?: string;
	isAttachment?: boolean;
};

const mediaControllerStyles = {
	'--media-controls-transition':
		'transform 200ms cubic-bezier(0.4, 0, 0.2, 1), opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)',
	'--media-control-background': 'var(--background-primary)',
	'--media-control-hover-background': 'var(--background-secondary)',
	'--media-primary-color': 'var(--text-primary)',
	'--media-secondary-color': 'var(--background-primary)',
	'--media-tooltip-z-index': 'var(--z-index-tooltip)',
} as const;

const timeRangeStyles = {
	'--media-range-track-background': 'var(--background-secondary)',
	'--media-range-bar-color': 'var(--brand-primary-light)',
	'--media-range-track-height': '4px',
	'--media-range-track-border-radius': '0px',
	'--media-range-track-outline': 'none',
	'--media-range-track-outline-offset': '0px',
	'--media-range-thumb-width': '0px',
	'--media-range-thumb-height': '0px',
	'--media-preview-time-background': 'var(--background-primary)',
	'--media-preview-time-text-shadow': 'none',
	'--media-preview-time-padding': '4px 8px',
	'--media-preview-time-border-radius': '4px',
	'--media-preview-time-font-size': '11px',
	'--media-preview-time-margin-bottom': '8px',
	'--media-range-padding': '0',
	'--media-range-padding-left': '0',
	'--media-range-padding-right': '0',
	'--media-time-range-hover-display': 'block',
	'--media-time-range-hover-height': '20px',
	'--media-time-range-hover-bottom': '0',
} as const;

const volumeRangeStyles = {
	'--media-range-track-background': 'var(--background-secondary)',
	'--media-range-bar-color': 'var(--text-primary)',
	'--media-range-track-height': '4px',
	'--media-range-thumb-height': '0px',
	'--media-range-thumb-width': '0px',
} as const;

const timeDisplayStyles = {
	'--media-time-display-padding': '0 8px',
} as const;

const controlBarStyles = {
	'--media-control-padding': '0px',
	'--media-control-height': '20px',
	'--media-font-size': '12px',
} as const;

const PlayButton: FC = () => (
	<button type="button" className="group/play">
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/play:scale-105 group-active/play:scale-95">
			<Play size={28} className="text-white" />
		</div>
	</button>
);

const VideoOverlay: FC<{
	thumbHashURL?: string;
	posterLoaded: boolean;
	onInitialPlay: () => void;
	width: number;
	height: number;
	forceRenderPlaceholder?: boolean;
}> = ({thumbHashURL, posterLoaded, onInitialPlay, width, height, forceRenderPlaceholder}) => {
	const shouldShowPlaceholder = !posterLoaded || forceRenderPlaceholder;
	const aspectRatio = `${width} / ${height}`;
	const exactStyle = {
		width: `${width}px`,
		height: `${height}px`,
		borderRadius: '4px',
	};

	return (
		<div
			className="absolute inset-0 flex cursor-pointer items-center justify-center"
			onClick={onInitialPlay}
			onKeyDown={(e) => e.key === 'Enter' && onInitialPlay()}
			role="button"
			tabIndex={0}
			style={{
				width: '100%',
				aspectRatio,
			}}
		>
			<AnimatePresence>
				{shouldShowPlaceholder && thumbHashURL && (
					<motion.img
						key="placeholder"
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						exit={{opacity: 0}}
						transition={{duration: 0.3}}
						src={thumbHashURL}
						alt="Video thumbnail"
						className="absolute inset-0 h-full w-full rounded"
						style={exactStyle}
						width={width}
						height={height}
					/>
				)}
			</AnimatePresence>

			<PlayButton />

			{/* Show debug indicator when in developer mode */}
			{forceRenderPlaceholder && (
				<div className="absolute top-0 right-0 z-10 rounded-bl bg-red-500 px-1 py-0.5 font-medium text-white text-xs">
					DEV
				</div>
			)}
		</div>
	);
};

const VideoControls: FC = () => (
	<div className="absolute right-0 bottom-0 left-0 translate-y-0 transition-all duration-200 ease-in-out group-hover:translate-y-0 group-[&:not(:hover):not([media-paused])]:translate-y-full">
		<div className="relative h-1 w-full" style={{zIndex: 'var(--z-index-elevated-1)'}}>
			<MediaTimeRange className="absolute inset-0 h-full w-full" style={timeRangeStyles} />
		</div>

		<div className="relative bg-background-primary" style={{zIndex: 'var(--z-index-elevated-2)'}}>
			<MediaControlBar className="flex h-12 w-full flex-row items-center px-3" style={controlBarStyles}>
				<div className="flex items-center space-x-2">
					<MediaPlayButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="play" aria-hidden="true">
							<Play size={18} />
						</span>
						<span slot="pause" aria-hidden="true">
							<Pause size={18} />
						</span>
					</MediaPlayButton>

					<MediaMuteButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="unmute" aria-hidden="true">
							<SpeakerHigh size={18} />
						</span>
						<span slot="mute" aria-hidden="true">
							<SpeakerX size={18} />
						</span>
					</MediaMuteButton>

					<MediaVolumeRange className="h-1 w-14" style={volumeRangeStyles} />

					<MediaTimeDisplay className="font-mono text-text-primary text-xs" showduration style={timeDisplayStyles} />
				</div>

				<div className="ml-auto flex items-center space-x-1">
					<MediaPlaybackRateButton
						className="flex h-8 w-8 items-center justify-center text-text-primary text-xs transition-all hover:scale-105 hover:bg-background-secondary active:scale-95"
						rates={VIDEO_CONFIG.PLAYBACK_RATES}
					/>

					<MediaPipButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<MonitorPlay size={18} />
					</MediaPipButton>

					<MediaFullscreenButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="enter" aria-hidden="true">
							<ArrowsOut size={18} />
						</span>
						<span slot="exit" aria-hidden="true">
							<ArrowsIn size={18} />
						</span>
					</MediaFullscreenButton>
				</div>
			</MediaControlBar>
		</div>
	</div>
);

const EmbedVideo: FC<EmbedVideoProps> = ({src, width, height, placeholder, isAttachment}) => {
	const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const posterSrc = `${src}?format=webp`;
	const [posterLoaded, setPosterLoaded] = useState(ImageCacheUtils.isCached(posterSrc));
	const [forceRenderPlaceholders, setForceRenderPlaceholders] = useState(
		DeveloperOptionsStore.getForceRenderPlaceholders(),
	);

	// Update when developer options change
	useEffect(() => {
		const handleStoreChange = () => {
			setForceRenderPlaceholders(DeveloperOptionsStore.getForceRenderPlaceholders());
		};

		DeveloperOptionsStore.addChangeListener(handleStoreChange);
		return () => {
			DeveloperOptionsStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	// Convert placeholder to thumbhash data URL
	const thumbHashUrl = useMemo(() => {
		if (!placeholder) return undefined;
		try {
			const binary = atob(placeholder);
			const bytes = new Uint8Array(binary.length);
			for (let i = 0; i < binary.length; i++) {
				bytes[i] = binary.charCodeAt(i);
			}
			return thumbHashToDataURL(bytes);
		} catch (e) {
			console.error('Failed to parse ThumbHash:', e);
			return undefined;
		}
	}, [placeholder]);

	// Calculate dimensions
	const result = videoCalculator.calculate(
		{width, height},
		{
			forceScale: true,
			isAttachment,
		},
	);

	const {style: containerStyle, dimensions} = result;
	const aspectRatio = `${dimensions.width} / ${dimensions.height}`;

	// Ensure exact dimensions
	const exactWidth = Math.round(dimensions.width);
	const exactHeight = Math.round(dimensions.height);

	// Load the poster image
	useEffect(() => {
		// Skip loading if in forced placeholder mode
		if (forceRenderPlaceholders) {
			return;
		}

		ImageCacheUtils.loadImage(
			posterSrc,
			() => setPosterLoaded(true),
			() => setPosterLoaded(false),
		);
	}, [posterSrc, forceRenderPlaceholders]);

	const handleInitialPlay = useCallback(() => {
		if (videoRef.current) {
			videoRef.current.play().catch(console.error);
			setHasPlayedOnce(true);
		}
	}, []);

	return (
		<motion.div
			className="h-full w-full select-none"
			style={{
				...containerStyle,
				minHeight: `${exactHeight}px`,
				aspectRatio,
			}}
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			transition={{duration: 0.2}}
		>
			<MediaController
				className="group relative h-full w-full overflow-hidden rounded-md bg-black"
				autohide={VIDEO_CONFIG.AUTOHIDE_DELAY}
				defaultsubtitles
				style={{
					...mediaControllerStyles,
					aspectRatio,
					width: '100%',
					minHeight: `${exactHeight}px`,
				}}
			>
				{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
				<video
					slot="media"
					src={src}
					className="h-full w-full object-contain"
					playsInline
					preload="metadata"
					ref={videoRef}
					style={{
						aspectRatio,
						width: '100%',
						minHeight: `${exactHeight}px`,
					}}
				/>

				<MediaPosterImage
					slot="poster"
					src={posterSrc}
					className="h-full w-full object-cover"
					style={{
						aspectRatio,
						width: '100%',
						minHeight: `${exactHeight}px`,
					}}
				/>

				{(!hasPlayedOnce || forceRenderPlaceholders) && (
					<VideoOverlay
						thumbHashURL={thumbHashUrl}
						posterLoaded={posterLoaded}
						onInitialPlay={handleInitialPlay}
						width={exactWidth}
						height={exactHeight}
						forceRenderPlaceholder={forceRenderPlaceholders}
					/>
				)}

				<VideoControls />
			</MediaController>
		</motion.div>
	);
};

export default EmbedVideo;
