import {LRUCache} from 'lru-cache';

// Configure a cache for storing image load status
const imageCache = new LRUCache<string, boolean>({
	max: 500, // Increase cache size to handle more images
	ttl: 1000 * 60 * 10, // 10 minute time-to-live
});

/**
 * Checks if an image is already loaded in cache
 */
export const isCached = (src: string | null): boolean => {
	if (!src) return false;
	return imageCache.has(src);
};

/**
 * Legacy method for backward compatibility
 */
export const hasImage = (src: string | null): boolean => {
	return isCached(src);
};

/**
 * Adds an image URL to the cache
 */
export const addToCache = (src: string | null): void => {
	if (!src) return;
	imageCache.set(src, true);
};

/**
 * Preloads an image and returns a promise that resolves when loaded
 * or rejects when there's an error
 */
export const preloadImage = (src: string | null): Promise<void> => {
	if (!src) {
		return Promise.reject(new Error('No source provided'));
	}

	// If already cached, return immediately
	if (imageCache.has(src)) {
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		const image = new Image();

		// Set up load handlers
		image.onload = () => {
			imageCache.set(src, true);
			resolve();
		};

		image.onerror = () => {
			reject(new Error(`Failed to load image: ${src}`));
		};

		// Start loading the image
		image.src = src;
	});
};

/**
 * Load an image with callbacks (older API style)
 */
export const loadImage = (src: string | null, onLoad: () => void, onError?: () => void): void => {
	if (!src) {
		onError?.();
		return;
	}

	// If already in cache, call onLoad immediately
	if (imageCache.has(src)) {
		onLoad();
		return;
	}

	const image = new Image();

	image.onload = () => {
		imageCache.set(src, true);
		onLoad();
	};

	image.onerror = () => {
		onError?.();
	};

	image.src = src;
};

/**
 * Clear the image cache (useful for testing or when memory needs to be freed)
 */
export const clearCache = (): void => {
	imageCache.clear();
};
