import {Star} from '@phosphor-icons/react';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as UserSettingsActionCreators from '~/actions/UserSettingsActionCreators';
import {ImagePreviewModal} from '~/components/channel/embeds/media/ImagePreviewModal';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import UserSettingsStore, {type FavoriteGif} from '~/stores/UserSettingsStore';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const MEDIA_CONFIG = {
	MAX_WIDTH: 400,
	MAX_HEIGHT: 300,
} as const;

const mediaCalculator = createCalculator({
	maxWidth: MEDIA_CONFIG.MAX_WIDTH,
	maxHeight: MEDIA_CONFIG.MAX_HEIGHT,
	responsive: true,
});

type BaseEmbedProps = {
	embedURL: string;
	naturalWidth: number;
	naturalHeight: number;
	placeholder?: string;
};

type VideoConfig = {
	autoplay?: boolean;
	loop?: boolean;
	muted?: boolean;
	playsInline?: boolean;
	controls?: boolean;
	preload?: 'none' | 'metadata' | 'auto';
};

const useMediaLoading = (src: string, placeholder?: string) => {
	const [loadingState, setLoadingState] = useState({
		loaded: ImageCacheUtils.isCached(`${src}?format=webp`),
		error: false,
	});

	const [forceRenderPlaceholders, setForceRenderPlaceholders] = useState(
		DeveloperOptionsStore.getForceRenderPlaceholders(),
	);

	// Update when developer options change
	useEffect(() => {
		const handleStoreChange = () => {
			setForceRenderPlaceholders(DeveloperOptionsStore.getForceRenderPlaceholders());
		};

		DeveloperOptionsStore.addChangeListener(handleStoreChange);
		return () => {
			DeveloperOptionsStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	const thumbHashURL = useMemo(() => {
		if (!placeholder) return undefined;
		try {
			const binary = atob(placeholder);
			const bytes = new Uint8Array(binary.length);
			for (let i = 0; i < binary.length; i++) {
				bytes[i] = binary.charCodeAt(i);
			}
			return thumbHashToDataURL(bytes);
		} catch {
			return undefined;
		}
	}, [placeholder]);

	useEffect(() => {
		if (forceRenderPlaceholders) {
			return;
		}

		ImageCacheUtils.loadImage(
			`${src}?format=webp`,
			() => setLoadingState({loaded: true, error: false}),
			() => setLoadingState({loaded: false, error: true}),
		);
	}, [src, forceRenderPlaceholders]);

	return {
		...loadingState,
		thumbHashURL,
		forceRenderPlaceholders,
	};
};

const useFavoriteGif = (embedUrl: string, gifData: FavoriteGif) => {
	const {favorite_gifs: favoriteGifs} = UserSettingsStore.useStore();
	const isFavorited = favoriteGifs.some((gif) => gif.url === embedUrl);

	const toggleFavorite = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			if (isFavorited) {
				UserSettingsActionCreators.removeFavoriteGif(embedUrl);
			} else {
				UserSettingsActionCreators.addFavoriteGif(gifData);
			}
		},
		[embedUrl, gifData, isFavorited],
	);

	return {isFavorited, toggleFavorite};
};

const useImagePreview = (
	proxyUrl: string,
	embedUrl: string,
	naturalWidth: number,
	naturalHeight: number,
	type: 'gifv' | 'gif' | 'image',
) => {
	return useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.type === 'click' && (event as React.MouseEvent).button !== 0) {
				return;
			}
			event.preventDefault();
			event.stopPropagation();
			ModalActionCreators.push(() => (
				<ImagePreviewModal
					src={proxyUrl}
					originalSrc={embedUrl}
					naturalWidth={naturalWidth}
					naturalHeight={naturalHeight}
					type={type}
				/>
			));
		},
		[proxyUrl, embedUrl, naturalWidth, naturalHeight, type],
	);
};

const FavoriteButton: FC<{
	isFavorited: boolean;
	onClick: (e: React.MouseEvent) => void;
}> = ({isFavorited, onClick}) => (
	<Tooltip text={isFavorited ? i18n.Messages.REMOVE_FROM_FAVORITES : i18n.Messages.ADD_TO_FAVORITES}>
		<button
			type="button"
			className={clsx(
				'absolute top-2 left-2 z-20 text-white transition-transform duration-200',
				isFavorited ? 'text-yellow-500 hover:scale-110' : 'hover:text-yellow-500',
			)}
			onClick={onClick}
			aria-label={isFavorited ? i18n.Messages.REMOVE_FROM_FAVORITES : i18n.Messages.ADD_TO_FAVORITES}
		>
			<Star weight={isFavorited ? 'fill' : 'bold'} className="h-7 w-7" />
		</button>
	</Tooltip>
);

const GifIndicator: FC = () => (
	<div
		className={clsx(
			'absolute top-2 left-2 z-10 rounded bg-black/60 px-1.5 py-0.5 font-medium text-sm text-white leading-none',
		)}
		aria-hidden="true"
	>
		GIF
	</div>
);

type GifWrapperProps = {
	naturalWidth: number;
	naturalHeight: number;
	onPreviewClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
	isFavorited: boolean;
	onFavoriteClick: (e: React.MouseEvent) => void;
	children: React.ReactNode;
	forceRenderPlaceholders?: boolean;
};

const GifWrapper: FC<GifWrapperProps> = ({
	naturalWidth,
	naturalHeight,
	onPreviewClick,
	isFavorited,
	onFavoriteClick,
	children,
	forceRenderPlaceholders,
}) => {
	const {style, dimensions} = mediaCalculator.calculate(
		{width: naturalWidth, height: naturalHeight},
		{forceScale: true},
	);
	const [isHovered, setIsHovered] = useState(false);

	// Ensure exact dimensions
	const exactStyle = {
		...style,
		width: `${Math.round(dimensions.width)}px`,
		height: `${Math.round(dimensions.height)}px`,
		borderRadius: '4px',
	};

	return (
		<motion.div
			className="relative flex cursor-pointer flex-col overflow-hidden rounded-md"
			style={exactStyle}
			role="button"
			tabIndex={0}
			onClick={onPreviewClick}
			onKeyDown={(e) => e.key === 'Enter' && onPreviewClick(e)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			transition={{duration: 0.2}}
		>
			{isHovered ? <FavoriteButton isFavorited={isFavorited} onClick={onFavoriteClick} /> : <GifIndicator />}

			{/* Show debug indicator when in developer mode */}
			{forceRenderPlaceholders && (
				<div className="absolute top-0 right-0 z-10 rounded-bl bg-red-500 px-1 py-0.5 font-medium text-white text-xs">
					DEV
				</div>
			)}

			{children}
		</motion.div>
	);
};

type ImagePreviewHandlerProps = {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	type: 'gifv' | 'gif' | 'image';
	handlePress?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	children: React.ReactNode;
};

const ImagePreviewHandler: FC<ImagePreviewHandlerProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	type,
	handlePress,
	children,
}) => {
	const openImagePreview = useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.type === 'click' && (event as React.MouseEvent).button !== 0) {
				return;
			}

			if (handlePress) {
				event.preventDefault();
				event.stopPropagation();
				handlePress(event);
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			ModalActionCreators.push(() => (
				<ImagePreviewModal
					src={src}
					originalSrc={originalSrc}
					naturalWidth={naturalWidth}
					naturalHeight={naturalHeight}
					type={type}
				/>
			));
		},
		[src, originalSrc, naturalWidth, naturalHeight, handlePress, type],
	);

	return (
		<>
			{/* biome-ignore lint/a11y/useAnchorContent: <explanation> */}
			<a
				tabIndex={-1}
				aria-hidden={true}
				className="absolute inset-0 z-10 no-underline"
				href={originalSrc}
				data-safe-src={src}
				onClick={openImagePreview}
				draggable={false}
			/>
			<div
				className="h-full w-full"
				tabIndex={0}
				aria-label={type === 'gifv' ? 'Animated GIF Video' : type === 'gif' ? 'Animated GIF' : 'Image'}
				role="button"
				onClick={openImagePreview}
				onKeyDown={(event) => event.key === 'Enter' && openImagePreview(event)}
			>
				{children}
			</div>
		</>
	);
};

export const EmbedGifv: FC<
	BaseEmbedProps & {
		videoProxyURL: string;
		videoURL: string;
		videoConfig?: VideoConfig;
	}
> = ({embedURL, videoProxyURL, videoURL, naturalWidth, naturalHeight, placeholder, videoConfig}) => {
	const {loaded, error, thumbHashURL, forceRenderPlaceholders} = useMediaLoading(videoProxyURL, placeholder);
	const videoRef = useRef<HTMLVideoElement>(null);

	const {isFavorited, toggleFavorite} = useFavoriteGif(embedURL, {
		url: embedURL,
		src: videoURL,
		proxy_src: videoProxyURL,
		width: naturalWidth,
		height: naturalHeight,
	});

	const {gif_auto_play: gifAutoPlay} = UserSettingsStore.useStore();
	const openImagePreview = useImagePreview(videoProxyURL, embedURL, naturalWidth, naturalHeight, 'gifv');

	// Calculate exact dimensions
	const {dimensions} = mediaCalculator.calculate({width: naturalWidth, height: naturalHeight}, {forceScale: true});
	const exactWidth = Math.round(dimensions.width);
	const exactHeight = Math.round(dimensions.height);
	const exactStyle = {
		width: `${exactWidth}px`,
		height: `${exactHeight}px`,
		borderRadius: '4px',
	};

	useEffect(() => {
		const video = videoRef.current;
		if (!video) return;

		video.loop = videoConfig?.loop ?? true;
		video.muted = videoConfig?.muted ?? true;
		video.playsInline = videoConfig?.playsInline ?? true;
		video.preload = videoConfig?.preload ?? 'auto';

		if (gifAutoPlay) {
			video.autoplay = true;
			video.play().catch(() => {});
		} else {
			video.autoplay = false;
		}
	}, [videoConfig, gifAutoPlay]);

	useEffect(() => {
		if (gifAutoPlay) return;

		const video = videoRef.current;
		if (!video) return;

		const wrapper = video.closest('.relative.flex');
		if (!wrapper) return;

		const handleMouseEnter = () => video.play().catch(() => {});
		const handleMouseLeave = () => {
			video.pause();
			video.currentTime = 0;
		};

		wrapper.addEventListener('mouseenter', handleMouseEnter);
		wrapper.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			wrapper.removeEventListener('mouseenter', handleMouseEnter);
			wrapper.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [gifAutoPlay]);

	return (
		<GifWrapper
			naturalWidth={naturalWidth}
			naturalHeight={naturalHeight}
			onPreviewClick={openImagePreview}
			isFavorited={isFavorited}
			onFavoriteClick={toggleFavorite}
			forceRenderPlaceholders={forceRenderPlaceholders}
		>
			<ImagePreviewHandler
				src={videoProxyURL}
				originalSrc={embedURL}
				naturalWidth={naturalWidth}
				naturalHeight={naturalHeight}
				type="gifv"
			>
				<div className="relative h-full w-full">
					<AnimatePresence>
						{(!loaded || error || forceRenderPlaceholders) && thumbHashURL && (
							<motion.img
								src={thumbHashURL}
								className="absolute inset-0 h-full w-full rounded"
								alt="Loading placeholder"
								style={exactStyle}
								width={exactWidth}
								height={exactHeight}
								initial={{opacity: 0}}
								animate={{opacity: 1}}
								exit={{opacity: 0}}
								transition={{duration: 0.2}}
							/>
						)}
					</AnimatePresence>

					<video
						className={clsx(
							'h-full w-full rounded-md object-contain transition-opacity duration-200',
							!loaded || error || forceRenderPlaceholders ? 'opacity-0' : 'opacity-100',
						)}
						controls={videoConfig?.controls ?? false}
						playsInline={videoConfig?.playsInline ?? true}
						loop={videoConfig?.loop ?? true}
						muted={videoConfig?.muted ?? true}
						poster={`${videoProxyURL}?format=webp`}
						preload={videoConfig?.preload ?? 'auto'}
						src={videoProxyURL}
						ref={videoRef}
						aria-label="Animated GIF Video"
						width={exactWidth}
						height={exactHeight}
						style={exactStyle}
					/>
				</div>
			</ImagePreviewHandler>
		</GifWrapper>
	);
};

export const EmbedGif: FC<BaseEmbedProps & {proxyURL: string}> = ({
	embedURL,
	proxyURL,
	naturalWidth,
	naturalHeight,
	placeholder,
}) => {
	const {loaded, error, thumbHashURL, forceRenderPlaceholders} = useMediaLoading(proxyURL, placeholder);

	const {isFavorited, toggleFavorite} = useFavoriteGif(embedURL, {
		url: embedURL,
		src: embedURL,
		proxy_src: proxyURL,
		width: naturalWidth,
		height: naturalHeight,
	});

	const openImagePreview = useImagePreview(proxyURL, embedURL, naturalWidth, naturalHeight, 'gif');

	// Calculate exact dimensions
	const {dimensions} = mediaCalculator.calculate({width: naturalWidth, height: naturalHeight}, {forceScale: true});
	const exactWidth = Math.round(dimensions.width);
	const exactHeight = Math.round(dimensions.height);
	const exactStyle = {
		width: `${exactWidth}px`,
		height: `${exactHeight}px`,
		borderRadius: '4px',
	};

	return (
		<GifWrapper
			naturalWidth={naturalWidth}
			naturalHeight={naturalHeight}
			onPreviewClick={openImagePreview}
			isFavorited={isFavorited}
			onFavoriteClick={toggleFavorite}
			forceRenderPlaceholders={forceRenderPlaceholders}
		>
			<ImagePreviewHandler
				src={proxyURL}
				originalSrc={embedURL}
				naturalWidth={naturalWidth}
				naturalHeight={naturalHeight}
				type="gif"
			>
				<div className="relative h-full w-full">
					<AnimatePresence>
						{(!loaded || error || forceRenderPlaceholders) && thumbHashURL && (
							<motion.img
								src={thumbHashURL}
								className="absolute inset-0 h-full w-full rounded"
								alt="Loading placeholder"
								style={exactStyle}
								width={exactWidth}
								height={exactHeight}
								initial={{opacity: 0}}
								animate={{opacity: 1}}
								exit={{opacity: 0}}
								transition={{duration: 0.2}}
							/>
						)}
					</AnimatePresence>

					<img
						alt="GIF"
						src={proxyURL}
						className={clsx(
							'absolute inset-0 h-full w-full rounded object-contain transition-opacity duration-200',
							!loaded || error || forceRenderPlaceholders ? 'opacity-0' : 'opacity-100',
						)}
						style={exactStyle}
						width={exactWidth}
						height={exactHeight}
						loading="lazy"
					/>
				</div>
			</ImagePreviewHandler>
		</GifWrapper>
	);
};
