import {ArrowSquareOut, Play} from '@phosphor-icons/react';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import type {MessageEmbed} from '~/records/MessageRecord';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const YOUTUBE_CONFIG = {
	DEFAULT_WIDTH: 400,
	ANIMATION_DURATION: 0.3,
	BUTTON_DELAY: 0.1,
} as const;

const youtubeCalculator = createCalculator({
	maxWidth: YOUTUBE_CONFIG.DEFAULT_WIDTH,
	responsive: true,
});

type EmbedYouTubeProps = {
	embed: MessageEmbed;
	width?: number;
};

type ThumbnailProps = {
	posterSrc: string;
	thumbHashURL?: string;
	posterLoaded: boolean;
	title?: string;
	onPlay: (event: React.MouseEvent | React.KeyboardEvent) => void;
	onOpenInNewTab: (event: React.MouseEvent | React.KeyboardEvent) => void;
	width: number;
	height: number;
	forceRenderPlaceholder?: boolean;
};

const PlayButton: FC<{onClick: (event: React.MouseEvent) => void}> = ({onClick}) => (
	<motion.button
		initial={{scale: 0.9, opacity: 0}}
		animate={{scale: 1, opacity: 1}}
		transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION, delay: YOUTUBE_CONFIG.BUTTON_DELAY}}
		className="group/play"
		onClick={onClick}
	>
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/play:scale-105 group-active/play:scale-95">
			<Play size={28} className="text-white" />
		</div>
	</motion.button>
);

const ExternalButton: FC<{onClick: (event: React.MouseEvent) => void}> = ({onClick}) => (
	<motion.button
		initial={{scale: 0.9, opacity: 0}}
		animate={{scale: 1, opacity: 1}}
		transition={{
			duration: YOUTUBE_CONFIG.ANIMATION_DURATION,
			delay: YOUTUBE_CONFIG.BUTTON_DELAY * 2,
		}}
		onClick={onClick}
		className="group/external"
	>
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/external:scale-105 group-active/external:scale-95">
			<ArrowSquareOut size={24} className="text-white" />
		</div>
	</motion.button>
);

const Thumbnail: FC<ThumbnailProps> = ({
	posterSrc,
	thumbHashURL,
	posterLoaded,
	title,
	onPlay,
	onOpenInNewTab,
	width,
	height,
	forceRenderPlaceholder,
}) => {
	const shouldShowPlaceholder = !posterLoaded || forceRenderPlaceholder;
	const exactStyle = {
		width: `${width}px`,
		height: `${height}px`,
		borderRadius: '4px',
	};

	return (
		<div
			className="group relative h-full w-full cursor-pointer overflow-hidden rounded-md bg-background-primary transition-all duration-200 ease-out hover:shadow-black/10 hover:shadow-lg"
			onClick={onPlay}
			onKeyDown={(e) => e.key === 'Enter' && onPlay(e)}
			role="button"
			tabIndex={0}
		>
			<AnimatePresence>
				{shouldShowPlaceholder && thumbHashURL && (
					<>
						<motion.img
							key="placeholder"
							initial={{opacity: 0}}
							animate={{opacity: 1}}
							exit={{opacity: 0}}
							transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
							src={thumbHashURL}
							alt="Video thumbnail"
							className="absolute inset-0 h-full w-full rounded"
							style={exactStyle}
							width={width}
							height={height}
						/>
						<motion.div
							key="overlay"
							initial={{opacity: 0}}
							animate={{opacity: 1}}
							exit={{opacity: 0}}
							transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
							className="absolute inset-0 bg-black/40 transition-opacity duration-200 group-hover:opacity-50"
						/>
					</>
				)}
			</AnimatePresence>

			<motion.img
				key="poster"
				initial={{opacity: 0}}
				animate={{opacity: posterLoaded && !forceRenderPlaceholder ? 1 : 0}}
				transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
				src={posterSrc}
				alt={title || 'Video thumbnail'}
				className="absolute inset-0 h-full w-full object-cover transition-transform duration-500 ease-out group-hover:scale-[1.02]"
				style={exactStyle}
				width={width}
				height={height}
			/>

			<div className="absolute inset-0 flex items-center justify-center">
				<div className="flex items-center gap-3">
					<PlayButton onClick={onPlay} />
					<ExternalButton onClick={onOpenInNewTab} />
				</div>
			</div>

			{/* Show debug indicator when in developer mode */}
			{forceRenderPlaceholder && (
				<div className="absolute top-0 right-0 z-10 rounded-bl bg-red-500 px-1 py-0.5 font-medium text-white text-xs">
					DEV
				</div>
			)}
		</div>
	);
};

export const EmbedYouTube: FC<EmbedYouTubeProps> = ({embed, width = YOUTUBE_CONFIG.DEFAULT_WIDTH}) => {
	const [hasInteracted, setHasInteracted] = useState(false);
	const [posterLoaded, setPosterLoaded] = useState(false);
	const [forceRenderPlaceholders, setForceRenderPlaceholders] = useState(
		DeveloperOptionsStore.getForceRenderPlaceholders(),
	);

	// Update when developer options change
	useEffect(() => {
		const handleStoreChange = () => {
			setForceRenderPlaceholders(DeveloperOptionsStore.getForceRenderPlaceholders());
		};

		DeveloperOptionsStore.addChangeListener(handleStoreChange);
		return () => {
			DeveloperOptionsStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	if (!(embed.video && embed.thumbnail && embed.thumbnail.proxy_url)) {
		return null;
	}

	const posterSrc = embed.thumbnail.proxy_url;

	// Convert placeholder to thumbhash data URL
	const thumbHashUrl = useMemo(() => {
		if (!embed.thumbnail?.placeholder) return undefined;
		try {
			const binary = atob(embed.thumbnail.placeholder);
			const bytes = new Uint8Array(binary.length);
			for (let i = 0; i < binary.length; i++) {
				bytes[i] = binary.charCodeAt(i);
			}
			return thumbHashToDataURL(bytes);
		} catch (e) {
			console.error('Failed to parse ThumbHash:', e);
			return undefined;
		}
	}, [embed.thumbnail?.placeholder]);

	const result = youtubeCalculator.calculate(
		{
			width: embed.video.width!,
			height: embed.video.height!,
		},
		{
			forceScale: true,
			maxWidth: width,
		},
	);

	const {style: containerStyle, dimensions} = result;
	const aspectRatio = `${dimensions.width} / ${dimensions.height}`;

	// Ensure exact dimensions
	const exactWidth = Math.round(dimensions.width);
	const exactHeight = Math.round(dimensions.height);

	// Load the poster image
	useEffect(() => {
		// Skip loading if in forced placeholder mode
		if (forceRenderPlaceholders) {
			return;
		}

		ImageCacheUtils.loadImage(
			posterSrc,
			() => setPosterLoaded(true),
			() => setPosterLoaded(false),
		);
	}, [posterSrc, forceRenderPlaceholders]);

	const handleInitialPlay = useCallback((event: React.MouseEvent | React.KeyboardEvent) => {
		event.stopPropagation();
		setHasInteracted(true);
	}, []);

	const handleOpenInNewTab = useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			event.stopPropagation();
			window.open(embed.url, '_blank');
		},
		[embed.url],
	);

	if (!hasInteracted) {
		return (
			<motion.div
				className="w-full select-none rounded-md"
				style={{
					...containerStyle,
					minHeight: `${exactHeight}px`,
					aspectRatio,
				}}
				initial={{opacity: 0}}
				animate={{opacity: 1}}
				transition={{duration: 0.2}}
			>
				<Thumbnail
					posterSrc={posterSrc}
					thumbHashURL={thumbHashUrl}
					posterLoaded={posterLoaded}
					title={embed.title}
					onPlay={handleInitialPlay}
					onOpenInNewTab={handleOpenInNewTab}
					width={exactWidth}
					height={exactHeight}
					forceRenderPlaceholder={forceRenderPlaceholders}
				/>
			</motion.div>
		);
	}

	const embedVideoUrl = new URL(embed.video.url);
	embedVideoUrl.searchParams.set('autoplay', '1');
	embedVideoUrl.searchParams.set('auto_play', '1');

	return (
		<motion.div
			className="relative h-full w-full overflow-hidden rounded-md bg-black"
			style={{
				...containerStyle,
				minHeight: `${exactHeight}px`,
				aspectRatio,
			}}
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			transition={{duration: 0.2}}
		>
			<iframe
				allow="autoplay; fullscreen"
				sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
				src={embedVideoUrl.toString()}
				className="absolute inset-0 h-full w-full border-none"
				title={embed.title || 'YouTube video'}
				width={exactWidth}
				height={exactHeight}
			/>
		</motion.div>
	);
};
