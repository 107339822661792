import emojiRegex from 'emoji-regex';
import UnicodeEmojis from '~/lib/UnicodeEmojis';
import * as EmojiUtils from '~/utils/EmojiUtils';
import {EmojiKind, NodeType, PLAINTEXT_SURROGATES, type ParserResult} from '../types';
import {performanceMetrics} from '../utils/performance-metrics';

// Pre-compiled frequently used regex patterns
const VALID_EMOJI_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;
const SKIN_TONE_REGEX = /::skin-tone-(\d)/;
const NUMERIC_ID_REGEX = /^\d+$/;
const CUSTOM_EMOJI_REGEX = /^<(a)?:([a-zA-Z0-9_-]+):(\d+)>/;

// Plaintext symbols that should not be treated as emojis
const PLAINTEXT_SYMBOLS = new Set(['™', '™️', '©', '©️', '®', '®️']);

/**
 * Main entry point for parsing emojis
 * Tries to parse standard emojis, shortcodes, and custom emojis
 *
 * @param text - The text to parse
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no emoji found
 */
export function parseEmoji(text: string, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiParsers.parseEmoji');
	}

	// Try to parse as custom emoji first (higher priority)
	if (text.startsWith('<') && (text.startsWith('<:') || text.startsWith('<a:'))) {
		if (collectMetrics) {
			performanceMetrics.startOperation('EmojiParsers.parseCustomEmoji');
		}

		const customResult = parseCustomEmoji(text, collectMetrics);

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji');
		}

		if (customResult) {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseEmoji');
			}
			return customResult;
		}
	}

	// Try to parse as emoji shortcode
	if (text.startsWith(':')) {
		if (collectMetrics) {
			performanceMetrics.startOperation('EmojiParsers.parseEmojiShortcode');
		}

		const shortcodeResult = parseEmojiShortcode(text, collectMetrics);

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode');
		}

		if (shortcodeResult) {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseEmoji');
			}
			return shortcodeResult;
		}
	}

	// Check for complex emojis (like ZWJ sequences)
	const emojiRegexp = emojiRegex();
	const match = emojiRegexp.exec(text);

	if (match && match.index === 0) {
		const emoji = match[0];

		// Check if this emoji should be treated as plaintext
		if (PLAINTEXT_SURROGATES.has(emoji) || PLAINTEXT_SYMBOLS.has(emoji)) {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseEmoji');
			}
			return null;
		}

		// Handle complex emoji
		if (collectMetrics) {
			performanceMetrics.startOperation('EmojiUtils.convertToCodePoints');
		}

		const codepoints = EmojiUtils.convertToCodePoints(emoji);

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiUtils.convertToCodePoints');
		}

		if (collectMetrics) {
			performanceMetrics.startOperation('UnicodeEmojis.getSurrogateName');
		}

		const name = UnicodeEmojis.getSurrogateName(emoji, false);

		if (collectMetrics) {
			performanceMetrics.endOperation('UnicodeEmojis.getSurrogateName');
		}

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmoji');
		}

		return {
			node: {
				type: NodeType.Emoji,
				kind: {
					kind: EmojiKind.Standard,
					raw: emoji,
					codepoints,
					name,
				},
			},
			advance: emoji.length,
		};
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiParsers.parseEmoji');
	}

	return null;
}

/**
 * Parses a standard Unicode emoji
 *
 * @param text - The text to parse
 * @param start - The position to start parsing from
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no standard emoji found
 */
export function parseStandardEmoji(text: string, start: number, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiParsers.parseStandardEmoji.detail');
	}

	// Use spread once to get proper character boundaries
	const characters = [...text.slice(start)];
	if (characters.length === 0) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
		}
		return null;
	}

	// Get the first grapheme cluster (potentially a multi-codepoint emoji)
	const firstChar = characters[0];

	// Skip if this is in the plaintext set
	if (PLAINTEXT_SURROGATES.has(firstChar)) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
		}
		return null;
	}

	// Run the emoji regex on the full text from this position
	const regex = emojiRegex();
	const match = regex.exec(text.slice(start));

	if (match && match.index === 0) {
		const candidate = match[0];

		// Check if this emoji should be treated as plaintext
		if (PLAINTEXT_SURROGATES.has(candidate)) {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
			}
			return null;
		}

		// Here's the key fix - check if this is a single character without variation selector
		const hasVariationSelector = candidate.includes('\uFE0F');

		// Important unicode ranges that need variation selectors to be emojis
		const codePoint = candidate.codePointAt(0) || 0;
		const needsVariationSelector =
			// Characters that need VS16 to be considered emoji
			(codePoint >= 0x2190 && codePoint <= 0x21ff) || // Arrows block
			(codePoint >= 0x2300 && codePoint <= 0x23ff) || // Misc Technical
			(codePoint >= 0x2600 && codePoint <= 0x27bf) || // Misc Symbols & Dingbats
			(codePoint >= 0x2900 && codePoint <= 0x297f); // Supplemental Arrows-B - where ⤴ (U+2934) is located

		// If it needs a variation selector but doesn't have one, treat as text
		if (needsVariationSelector && !hasVariationSelector) {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
			}
			return null;
		}

		if (collectMetrics) {
			performanceMetrics.startOperation('EmojiUtils.convertToCodePoints');
		}

		const codepoints = EmojiUtils.convertToCodePoints(candidate);

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiUtils.convertToCodePoints');
		}

		if (collectMetrics) {
			performanceMetrics.startOperation('UnicodeEmojis.getSurrogateName');
		}

		const name = UnicodeEmojis.getSurrogateName(candidate, false);

		if (collectMetrics) {
			performanceMetrics.endOperation('UnicodeEmojis.getSurrogateName');
		}

		// Additional safeguard: if we don't have a name for this emoji, it's
		// probably not meant to be an emoji - treat it as plaintext
		if (!name || name === '') {
			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
			}
			return null;
		}

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
		}

		return {
			node: {
				type: NodeType.Emoji,
				kind: {
					kind: EmojiKind.Standard,
					raw: candidate,
					codepoints,
					name,
				},
			},
			advance: candidate.length,
		};
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji.detail');
	}

	return null;
}

/**
 * Parses an emoji shortcode like :smile: or :thumbsup::skin-tone-3:
 *
 * @param text - The text to parse
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no valid shortcode found
 */
export function parseEmojiShortcode(text: string, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiParsers.parseEmojiShortcode.detail');
	}

	if (!text.startsWith(':')) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return null;
	}

	const endPos = text.indexOf(':', 1);
	if (endPos === -1) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return null;
	}

	// Extract emoji name and possible skin tone
	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiParsers.extractEmojiName');
	}

	const {baseName, skinTone} = extractEmojiName(text.slice(1, endPos));

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiParsers.extractEmojiName');
	}

	if (!isValidEmojiName(baseName)) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return null;
	}

	// Try to find the emoji by name
	if (collectMetrics) {
		performanceMetrics.startOperation('UnicodeEmojis.findEmojiByName');
	}

	const emoji = UnicodeEmojis.findEmojiByName(baseName);

	if (collectMetrics) {
		performanceMetrics.endOperation('UnicodeEmojis.findEmojiByName');
	}

	if (!emoji) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return null;
	}

	// Some emoji-like characters should be treated as regular text
	// This includes plaintext symbols like ™, ©, ®
	if (PLAINTEXT_SURROGATES.has(emoji) || PLAINTEXT_SYMBOLS.has(emoji)) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return {
			node: {type: NodeType.Text, content: emoji},
			advance: endPos + 1,
		};
	}

	// Apply skin tone if specified
	let finalEmoji = emoji;
	if (skinTone !== undefined) {
		if (collectMetrics) {
			performanceMetrics.startOperation('UnicodeEmojis.findEmojiWithSkinTone');
		}

		finalEmoji = UnicodeEmojis.findEmojiWithSkinTone(baseName, skinTone) || emoji;

		if (collectMetrics) {
			performanceMetrics.endOperation('UnicodeEmojis.findEmojiWithSkinTone');
		}
	}

	if (!finalEmoji) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
		}
		return null;
	}

	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiUtils.convertToCodePoints for shortcode');
	}

	const codepoints = EmojiUtils.convertToCodePoints(finalEmoji);

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiUtils.convertToCodePoints for shortcode');
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode.detail');
	}

	return {
		node: {
			type: NodeType.Emoji,
			kind: {
				kind: EmojiKind.Standard,
				raw: finalEmoji,
				codepoints,
				name: baseName,
			},
		},
		advance: endPos + 1,
	};
}

/**
 * Extracts the base emoji name and optional skin tone from a shortcode
 *
 * @param fullName - The full emoji name from the shortcode
 * @returns Object with base name and optional skin tone
 */
export function extractEmojiName(fullName: string): {baseName: string; skinTone?: number} {
	const skinToneMatch = SKIN_TONE_REGEX.exec(fullName);
	if (!skinToneMatch) return {baseName: fullName};

	const tone = Number.parseInt(skinToneMatch[1], 10);
	if (tone >= 1 && tone <= 5) {
		return {
			baseName: fullName.slice(0, skinToneMatch.index!),
			skinTone: tone,
		};
	}

	return {baseName: fullName};
}

/**
 * Validates that an emoji name follows the correct format
 *
 * @param name - The emoji name to validate
 * @returns Whether the name is valid
 */
export function isValidEmojiName(name: string): boolean {
	return Boolean(name && VALID_EMOJI_NAME_REGEX.test(name));
}

/**
 * Parses a custom Discord emoji <:name:id> or <a:name:id> for animated
 *
 * @param text - The text to parse
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if not a valid custom emoji
 */
export function parseCustomEmoji(text: string, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('EmojiParsers.parseCustomEmoji.detail');
	}

	// Check if this looks like a custom emoji pattern
	if (!text.startsWith('<:') && !text.startsWith('<a:')) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji.detail');
		}
		return null;
	}

	// Use regex for more reliable parsing
	const match = CUSTOM_EMOJI_REGEX.exec(text);
	if (!match) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji.detail');
		}
		return null;
	}

	const animated = Boolean(match[1]);
	const name = match[2];
	const id = match[3];
	const advance = match[0].length;

	if (!name || !id || !NUMERIC_ID_REGEX.test(id)) {
		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji.detail');
		}
		return null;
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji.detail');
	}

	return {
		node: {
			type: NodeType.Emoji,
			kind: {
				kind: EmojiKind.Custom,
				name,
				id,
				animated,
			},
		},
		advance,
	};
}
