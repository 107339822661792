import {useEffect, useMemo, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

/**
 * Hook for managing image loading state with ThumbHash support
 * and developer options integration
 */
export const useImageLoading = (src: string, placeholder?: string) => {
	const [loaded, setLoaded] = useState(ImageCacheUtils.isCached(src));
	const [error, setError] = useState(false);
	const [forcePlaceholders, setForcePlaceholders] = useState(DeveloperOptionsStore.getForceRenderPlaceholders());

	// Listen for developer option changes
	useEffect(() => {
		const handleDevOptionsChange = () => {
			setForcePlaceholders(DeveloperOptionsStore.getForceRenderPlaceholders());
		};

		DeveloperOptionsStore.addChangeListener(handleDevOptionsChange);
		return () => {
			DeveloperOptionsStore.removeChangeListener(handleDevOptionsChange);
		};
	}, []);

	// Generate ThumbHash URL once
	const thumbHashURL = useMemo(() => {
		if (!placeholder) return null;
		try {
			// Convert the base64 thumbHash string to the byte array required by thumbHashToDataURL
			const binary = atob(placeholder);
			const bytes = new Uint8Array(binary.length);
			for (let i = 0; i < binary.length; i++) {
				bytes[i] = binary.charCodeAt(i);
			}
			return thumbHashToDataURL(bytes);
		} catch (e) {
			console.error('Failed to parse ThumbHash:', e);
			return null;
		}
	}, [placeholder]);

	useEffect(() => {
		// Skip loading if in forced placeholder mode
		if (forcePlaceholders) {
			return;
		}

		// Reset state when the source changes
		if (!ImageCacheUtils.isCached(src)) {
			setLoaded(false);
		}
		setError(false);

		// Skip loading if already cached
		if (ImageCacheUtils.isCached(src)) {
			setLoaded(true);
			return;
		}

		// Create an AbortController to handle cleanup
		let unmounted = false;

		// Load the image
		const image = new Image();

		image.onload = () => {
			if (unmounted) return;
			ImageCacheUtils.addToCache(src);
			setLoaded(true);
		};

		image.onerror = () => {
			if (unmounted) return;
			setError(true);
		};

		image.src = src;

		// Cleanup function
		return () => {
			unmounted = true;
			image.onload = null;
			image.onerror = null;
		};
	}, [src, forcePlaceholders]);

	return {
		loaded: forcePlaceholders ? false : loaded,
		error,
		thumbHashURL,
		isLoading: !loaded || forcePlaceholders,
		// Make the actual loaded state available if needed
		actuallyLoaded: loaded,
	};
};
