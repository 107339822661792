/**
 * Types for the dimension calculator
 */
type Dimensions = {
	width: number;
	height: number;
};

type CalculatorOptions = {
	maxWidth: number;
	maxHeight?: number;
	forceScale?: boolean;
	isAttachment?: boolean;
	responsive?: boolean; // Added responsive option
};

type CalculateOptions = {
	preserve?: boolean;
	forceScale?: boolean;
	isAttachment?: boolean;
	aspectRatio?: boolean; // Added aspectRatio option
	maxWidth?: number; // Added maxWidth option for overrides
};

type CalculationResult = {
	dimensions: Dimensions;
	style: Record<string, string>;
};

/**
 * Creates a calculator for image dimensions with a focus on exact pixel values
 * to prevent layout shifts and ensure consistent rendering.
 */
export const createCalculator = (options: CalculatorOptions) => {
	const {maxWidth = 400, maxHeight = maxWidth, forceScale = false, isAttachment = false, responsive = false} = options;

	const calculate = (originalDimensions: Dimensions, calcOptions: CalculateOptions = {}): CalculationResult => {
		const {
			forceScale: optionsForceScale = forceScale,
			isAttachment: optionsIsAttachment = isAttachment,
			preserve = false,
			aspectRatio = false,
			maxWidth: overrideMaxWidth,
		} = calcOptions;

		// Use override max width if provided
		const effectiveMaxWidth = overrideMaxWidth !== undefined ? overrideMaxWidth : maxWidth;

		const {width: originalWidth, height: originalHeight} = originalDimensions;

		// Calculate aspect ratio
		const aspectRatioValue = originalWidth / originalHeight;

		// Calculate final dimensions
		let finalWidth = 0;
		let finalHeight = 0;

		// If dimensions need to be scaled down
		if (originalWidth > effectiveMaxWidth || originalHeight > maxHeight) {
			if (aspectRatioValue > 1) {
				// Landscape orientation - constrain by width
				finalWidth = Math.min(originalWidth, effectiveMaxWidth);
				finalHeight = Math.round(finalWidth / aspectRatioValue);

				// If height still exceeds maximum, constrain by height
				if (finalHeight > maxHeight) {
					finalHeight = maxHeight;
					finalWidth = Math.round(finalHeight * aspectRatioValue);
				}
			} else {
				// Portrait orientation - constrain by height
				finalHeight = Math.min(originalHeight, maxHeight);
				finalWidth = Math.round(finalHeight * aspectRatioValue);

				// If width still exceeds maximum, constrain by width
				if (finalWidth > effectiveMaxWidth) {
					finalWidth = effectiveMaxWidth;
					finalHeight = Math.round(finalWidth / aspectRatioValue);
				}
			}
		} else {
			// No scaling needed, use original dimensions
			finalWidth = originalWidth;
			finalHeight = originalHeight;
		}

		// For thumbnails and forced scaling, ensure exact dimensions
		if (optionsForceScale) {
			if (preserve || aspectRatio) {
				// Maintain aspect ratio while forcing to fill available space
				if (aspectRatioValue > 1) {
					finalHeight = maxHeight;
					finalWidth = Math.round(finalHeight * aspectRatioValue);
					if (finalWidth > effectiveMaxWidth) {
						finalWidth = effectiveMaxWidth;
						finalHeight = Math.round(finalWidth / aspectRatioValue);
					}
				} else {
					finalWidth = effectiveMaxWidth;
					finalHeight = Math.round(finalWidth / aspectRatioValue);
					if (finalHeight > maxHeight) {
						finalHeight = maxHeight;
						finalWidth = Math.round(finalHeight * aspectRatioValue);
					}
				}
			} else {
				// Force to fill entire container without maintaining aspect ratio
				finalWidth = effectiveMaxWidth;
				finalHeight = maxHeight;
			}
		}

		// Create style object with exact pixel dimensions
		const style: Record<string, string> = {};

		// Apply different styling based on options
		if (responsive) {
			style.maxWidth = '100%';
			style.width = `${finalWidth}px`;
			style.height = `${finalHeight}px`;
			style.aspectRatio = `${finalWidth} / ${finalHeight}`;
		} else {
			style.width = `${finalWidth}px`;
			style.height = `${finalHeight}px`;
		}

		// Add specific styling for different use cases
		if (optionsIsAttachment) {
			// Attachment styling
			style.display = 'block';
			style.maxHeight = '100%';
		} else if (optionsForceScale) {
			// Thumbnail styling
			style.flexShrink = '0';
			style.display = 'block';
		}

		return {
			dimensions: {width: finalWidth, height: finalHeight},
			style,
		};
	};

	return {calculate};
};
