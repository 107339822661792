import clsx from 'clsx';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useState} from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {ImagePreviewModal} from '~/components/channel/embeds/media/ImagePreviewModal';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import {createCalculator} from '~/utils/DimensionUtils';
import {useImageLoading} from './hooks/useImageLoading';

type ImagePreviewHandlerProps = {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	handlePress?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	children: React.ReactNode;
};

type EmbedImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	width: number;
	height: number;
	placeholder?: string;
	constrain?: boolean;
	isInline?: boolean;
	isAttachment?: boolean;
	isThumbnail?: boolean;
	altText?: string;
	handlePress?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const ImagePreviewHandler: FC<ImagePreviewHandlerProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	handlePress,
	children,
}) => {
	const openImagePreview = useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.type === 'click' && (event as React.MouseEvent).button !== 0) {
				return;
			}

			if (handlePress) {
				event.preventDefault();
				event.stopPropagation();
				handlePress(event);
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			ModalActionCreators.push(() => (
				<ImagePreviewModal
					src={src}
					originalSrc={originalSrc}
					naturalWidth={naturalWidth}
					naturalHeight={naturalHeight}
					type="image"
				/>
			));
		},
		[src, originalSrc, naturalWidth, naturalHeight, handlePress],
	);

	return (
		<>
			{/* biome-ignore lint/a11y/useAnchorContent: <explanation> */}
			<a
				tabIndex={-1}
				aria-hidden={true}
				className="absolute inset-0 z-10 no-underline"
				href={originalSrc}
				data-safe-src={src}
				onClick={openImagePreview}
				draggable={false}
			/>
			<div
				className="h-full w-full"
				tabIndex={0}
				aria-label="Image"
				role="button"
				onClick={openImagePreview}
				onKeyDown={(event) => event.key === 'Enter' && openImagePreview(event)}
			>
				{children}
			</div>
		</>
	);
};

const AltTextBadge: FC<{altText: string}> = ({altText}) => (
	<div className="absolute bottom-2 left-2 z-20">
		<Tooltip
			position="right"
			maxWidth="xl"
			text={() => (
				<div className="flex flex-col gap-1">
					<div className="font-semibold text-white text-xs uppercase">Image Description (Alt Text)</div>
					<div className="text-sm text-white">{altText}</div>
				</div>
			)}
		>
			<div className="select-none rounded bg-black/75 px-1.5 py-0.5 font-medium text-white text-xs">ALT</div>
		</Tooltip>
	</div>
);

/**
 * Hook for managing image loading with developer options support
 */
const useImageWithDevOptions = (src: string, placeholder?: string) => {
	// Get the regular loading states
	const loadingState = useImageLoading(src, placeholder);

	// Check if developer forced placeholders mode is enabled
	const forcePlaceholders = DeveloperOptionsStore.getForceRenderPlaceholders();

	// If force placeholder mode is on, we override the loaded state
	return {
		...loadingState,
		loaded: forcePlaceholders ? false : loadingState.loaded,
		// We still expose the real loaded state in case it's needed
		actuallyLoaded: loadingState.loaded,
	};
};

export const EmbedImage: FC<EmbedImageProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	width,
	height,
	placeholder,
	constrain,
	className,
	isInline,
	isAttachment,
	isThumbnail,
	altText,
	handlePress,
}) => {
	// Use our enhanced hook with developer options support
	const {loaded, error, thumbHashURL} = useImageWithDevOptions(src, placeholder);

	// Subscribe to changes in developer options
	const [forceRenderPlaceholders, setForceRenderPlaceholders] = useState(
		DeveloperOptionsStore.getForceRenderPlaceholders(),
	);

	// Update when developer options change
	useEffect(() => {
		const handleStoreChange = () => {
			setForceRenderPlaceholders(DeveloperOptionsStore.getForceRenderPlaceholders());
		};

		DeveloperOptionsStore.addChangeListener(handleStoreChange);
		return () => {
			DeveloperOptionsStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	// Calculate dimensions based on the type of image
	const calculator = createCalculator({
		maxWidth: width,
		maxHeight: height,
		forceScale: !!isThumbnail,
		isAttachment: !!isAttachment,
	});

	const result = calculator.calculate(
		{width: naturalWidth, height: naturalHeight},
		{
			preserve: constrain,
			forceScale: !!isThumbnail,
			isAttachment: !!isAttachment,
		},
	);

	const {style: containerStyle, dimensions} = result;
	const shouldRenderPlaceholder = error || !loaded || forceRenderPlaceholders;

	// Ensure exact dimensions for both container and images
	const exactWidth = Math.round(dimensions.width);
	const exactHeight = Math.round(dimensions.height);

	// Create a precise style object for the exact dimensions
	const exactStyle = {
		width: `${exactWidth}px`,
		height: `${exactHeight}px`,
		borderRadius: '4px',
	};

	return (
		<div className="flex flex-auto flex-col">
			<div className={clsx('flex h-full w-full flex-auto flex-row', isInline && 'justify-end')}>
				{/* Outer container with exact dimensions */}
				<motion.div
					className="relative block overflow-hidden rounded"
					style={{
						...containerStyle,
						...exactStyle,
					}}
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{duration: 0.2}}
				>
					{altText && <AltTextBadge altText={altText} />}

					{/* Show debug indicator when force placeholder mode is active */}
					{forceRenderPlaceholders && (
						<div className="absolute top-0 right-0 z-10 rounded-bl bg-red-500 px-1 py-0.5 font-medium text-white text-xs">
							DEV
						</div>
					)}

					<ImagePreviewHandler
						src={src}
						originalSrc={originalSrc}
						naturalWidth={naturalWidth}
						naturalHeight={naturalHeight}
						handlePress={handlePress}
					>
						<div className="relative h-full w-full">
							{/* ThumbHash placeholder with precise dimensions */}
							<AnimatePresence>
								{shouldRenderPlaceholder && thumbHashURL && (
									<motion.img
										key="placeholder"
										src={thumbHashURL}
										alt=""
										className="absolute inset-0 h-full w-full rounded"
										style={exactStyle}
										width={exactWidth}
										height={exactHeight}
										initial={{opacity: 0}}
										animate={{opacity: 1}}
										exit={{opacity: 0}}
										transition={{duration: 0.2}}
									/>
								)}
							</AnimatePresence>

							{/* Actual image with exact same dimensions */}
							<img
								alt={altText || ''}
								src={src}
								className={clsx(
									'absolute inset-0 h-full w-full rounded object-contain',
									'transition-opacity duration-300',
									shouldRenderPlaceholder ? 'opacity-0' : 'opacity-100',
									className,
								)}
								style={exactStyle}
								width={exactWidth}
								height={exactHeight}
								loading="lazy"
							/>
						</div>
					</ImagePreviewHandler>
				</motion.div>
			</div>
		</div>
	);
};
